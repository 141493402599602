export default {
	data: () => ({
		faq: {
			id: 0,
			name: "",
			description: "",
			display_order: "",
			isDisplay: "",
			faq_category_id: ""
		},
    loading: false,
		error: "",
		backUrl: "/faq",
		faqCategoriesList: []
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.faq = {
				id: 0,
				name: "",
				description: "",
				display_order: "",
				isDisplay: "",
				faq_category_id: ""
			};
		},
		validateFaq() {
			this.faq.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
		},
		update() {
			let _vm = this;
			this.axios
				.post("/faq/" + this.faq.id, {
					...this.faq,
					_method: "PATCH"
				})
				.then(function (response) {
					_vm.faq = response.data.data;
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},
		add() {
			let _vm = this;
			this.axios
				.post("/faq", _vm.faq)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/faq/" + this.$route.params.id)
				.then(function (response) {
					_vm.faq = response.data.data;
				})
				.catch(function () { });
		},
		getFaqcategories() {
			const _vm = this
			this.axios
				.get('/faqcategory', _vm.faqCategoriesList)
				.then(function (response) {
					_vm.faqCategoriesList = response.data.data.data
				})
				.catch(function () { })
		},
	},

	mounted() {
		this.getFaqcategories()

		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
